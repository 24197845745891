
@media print {
    aside {
        display: none !important;
    }
    header {
        display: none !important;
    }
    .ant-breadcrumb {
        display: none !important;
    }
    .app-title {
        display: none !important;
    }
    .ant-layout {
        padding: 0 !important;
    }
}

.ant-collapse-content-box {
    padding-top: 0 !important;    
    padding-left: 0 !important;
    padding-right: 0 !important;
}